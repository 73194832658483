$(document).ready(function() { 
  initBanner();
});

const initBanner = () => {
  const isHomePage = document.body.getAttribute('data-action') === 'home-show';
  const headerBanners = document.querySelectorAll('.header-notification-slot-container');
  const headerBannersContent = document.querySelectorAll('.header-notification-slot-container .html-slot-container');
  const currentLocale = headerBanners && headerBanners[0] && headerBanners[0].getAttribute('data-locale');
  let headerBannerStatus = window.sessionStorage.getItem(`hide_header_banner-${currentLocale}`);
  if(headerBanners.length && isHomePage && (!headerBannerStatus && headerBannersContent.length > 0 || headerBannerStatus < 0)) {
    document.querySelector('body') && document.querySelector('body').classList.add('header-banner-visible');
    headerBanners.forEach(b => {
      b.classList.remove('d-none');
    });
  }
}