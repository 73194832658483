window.isJilSander = true;

let processInclude = require('base/util');

function getHeaderHeight() {
  var root = document.documentElement;
  var headerHeight = $('.desktop-menu-wrapper')[0].clientHeight;
  root.style.setProperty('--header-height-calc', headerHeight + "px");
}


document.addEventListener("DOMContentLoaded", function(event) { 
  processInclude(require('core/components/wishlist'));
  processInclude(require('./login'));
  processInclude(require('tealium/tealiumEvents'));
  processInclude(require('./header-banner'));
  getHeaderHeight();

  setTimeout(() => {
    getHeaderHeight();
  }, 200);
});

